import { Action, createActionGroup, emptyProps, props } from '@ngrx/store';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';
import { ItemTypeDTO } from '../items.model';


export const ItemTypesApiActions = createActionGroup({
  source: 'Settings/ItemTypes/API',
  events: {
    'get all item types': emptyProps(),
    'get all item types success': props<{ itemTypes: ItemTypeDTO[] }>(),
    'get all item types failure': NgRxUtils.httpStatusProps(),

    'get item types by id': props<{ id: number }>(),
    'get item types by id success': props<{ itemType: ItemTypeDTO }>(),
    'get item types by id failure': NgRxUtils.httpStatusProps(),

    'create item types': props<{ itemType: Partial<ItemTypeDTO>, redirectToDetail: boolean, actions?: Action[] }>(),
    'create item types success': props<{ itemType: ItemTypeDTO }>(),
    'create item types failure': NgRxUtils.httpStatusProps(),

    'update item types': props<{ itemType: ItemTypeDTO }>(),
    'update item types success': props<{ itemType: ItemTypeDTO }>(),
    'update item types failure': NgRxUtils.httpStatusProps(),

    'delete item types': props<{ id: number }>(),
    'delete item types success': props<{ id: number }>(),
    'delete item types failure': NgRxUtils.httpStatusProps(),
  }
});

export const ItemTypesDefaultActions = createActionGroup({
  source: 'Settings/ItemTypes',
  events: {
    'clear errors': emptyProps(),
    'clear state': emptyProps()
  }
});
